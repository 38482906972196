<template>
  <div class="modal fade" :id="modalCopy" tabindex="-1" role="dialog" data-keyboard="false" data-backdrop="static" aria-labelledby="exampleModalScrollableTitle" aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalScrollableTitle">Link Perujuk</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"  @click="close()">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" >
        <a class="text-break" :href="linkData" target="_blank">
            {{linkData}}
        </a>
         <input type="hidden" id="linkUrl" :value="linkData">
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" @click="copyCommand('linkUrl')">Copy</button>
        <!-- <button type="button" class="btn btn-primary" @click="openTelmed(linkData)">Join Telekonsul</button> -->
        <button type="button" class="btn btn-secondary" data-dismiss="modal"  @click="close()">Tutup</button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
const blockUI = new BlockUI();
import BlockUI from "../../_js/BlockUI";
export default {
    data() {
        return {
            linkUrl: ''
        }
    },
    props: {
        modalCopy: { type: String, default: "modalCopy" },
        linkData: {type: String, default: ''}
    },
    methods:{
      openTelmed(link){
        window.open(link)
      },
       close() {
            blockUI.unblockPage();
            $("#" + this.modalCopy).modal("hide");
            this.$emit('setCloseModal', false)
            // this.setShowModalLinkTelekonsul(false);
        },
        copyCommand(element_id){
            let testingCodeToCopy = document.querySelector('#linkUrl')
            testingCodeToCopy.setAttribute('type', 'text')
            testingCodeToCopy.select()
            try {
                var successful = document.execCommand('copy');
                var msg = successful ? 'successful' : 'unsuccessful';
                 toastr.success('Berhasil disalin')
            } catch (err) {
                 toastr.error('Gagal disalin')
            }

            /* unselect the range */
            testingCodeToCopy.setAttribute('type', 'hidden')
            window.getSelection().removeAllRanges()
        },
    },
    mounted () {
      $("#" + this.modalCopy).modal();
    },
}
</script>

<style>

</style>