<template>
    <div class="text-center">
        <template v-if="checkPermission('BackOffice.Option.Perujuk.LinkButton')">
             <button
                @click="postLinkSSO"
                class="btn btn-sm btn-clean btn-icon btn-icon-md"
                title="Link"
            >
            <i class="la la-link"></i>
            </button>
        </template>
        <template v-if="checkPermission('RegistrasiResource.PUT.RegistrasiByInternal')">
        <button
            @click="editPerujuk()"
            class="btn btn-sm btn-clean btn-icon btn-icon-md"
            title="Edit"
        >
            <i class="la la-pencil-square"></i>
        </button>
        </template>
        
        <!-- <template v-if="checkPermission('PerujukResource.DELETE.Perujuk.id')">
        <button
            @click="deletePerujuk()"
            class="btn btn-sm btn-clean btn-icon btn-icon-md"
            title="Hapus"
        >
            <i class="la la-trash"></i>
        </button>
        </template> -->
    </div>
</template>

<script>
import CheckPermission from "../../../services/checkPermission.service";
import { RepositoryFactory } from "../../../repositories/RepositoryFactory";
import SweetAlertTemplate from "./../../_general/SweetAlert";
import BlockUI from "../../_js/BlockUI";
const blockUI = new BlockUI();
const PerujukRepository = RepositoryFactory.get("perujuk");
const RegistrasiPerujukRepository = RepositoryFactory.get("registrasiPerujuk");
const checkPermission = new CheckPermission();
const SweetAlert = Vue.extend(SweetAlertTemplate);
export default {
    props: {
        perujukVue: { type: Object },
        rowData: { type: Object }
    },
    methods: {
        deletePerujuk() {
            var vx = this;
            let instance = new SweetAlert().showConfirm({
                swalTitle: "Apakah Anda yakin?",
                swalText: "Data Perujuk akan terhapus.",
                swalType: "warning",
                onConfirmButton: function () {
                    blockUI.blockPage();
                    vx.delete();
                },
                onCancelButton: function () {
                    blockUI.unblockPage();
                }
            });
        },
        async delete() {
            var vx = this;

            await PerujukRepository.deletePerujuks(this.rowData.id)
                .then(response => {
                    new Promise((resolve, reject) => {
                        vx.perujukVue.fetch(resolve);
                    }).then((response) => {
                        let instance = new SweetAlert().showInfo({
                        swalTitle: "Berhasil!",
                        swalText: "Data Perujuk berhasil terhapus.",
                        swalType: "success",
                        onConfirmButton: function () {
                            blockUI.unblockPage();
                        }
                        });
                    });
                })
                .catch(error => {
                    let instance = new SweetAlert().showInfo({
                        swalTitle: "Gagal!",
                        swalText: "Data Perujuk gagal terhapus.",
                        swalType: "error",
                        onConfirmButton: function () {
                            blockUI.unblockPage();
                        }
                    });
                });
            },
        editPerujuk() {
            blockUI.blockPage();
            RegistrasiPerujukRepository.getPerujukId(this.rowData.id)
            .then(res => {
                blockUI.unblockPage();
                this.perujukVue.dataEdit = res.data;
                this.perujukVue.showModal();
            })
            .catch(err => {
                blockUI.unblockPage();
                toastr.error('Register : error retrive data');
            })
        },
        postLinkSSO () {
            blockUI.blockPage();
            PerujukRepository.requestLink(this.rowData.id)
            .then(res => {
                // new SweetAlert().showInfo({
                //         swalTitle: "Berhasil!",
                //         swalText: "Data Terkirim",
                //         swalType: "success",
                //         onConfirmButton: function () {
                //            blockUI.unblockPage();
                //         }
                // });
                this.perujukVue.linkPerujuk = res.data
                this.perujukVue.isShowLink = true
            })
            .catch(err => {
                blockUI.unblockPage();
                toastr.error('Register : error Link Data');
            })
        },
        checkPermission: function (method) {
            return checkPermission.isCanAccess(method);
        }
    }
}
</script>

<style>

</style>
